import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import {atom, useRecoilState} from 'recoil';
import {annotationChartYAxisAtom, isCurrentBloodSugarAtom} from '../../../states/recoilState';


const AnnotationChart = (props) => {
  const [maxData, setMaxData] = useState(null);
  const [minData, setMinData] = useState(null);
  const [chartOption, setChartOption] = useState([]);
  const [chartSeries, setChartSeries] = useState([]);

  const [isCurrentBloodSugar, setIsCurrentBloodSugar] = useRecoilState(isCurrentBloodSugarAtom);
  const [annotationChartYAxis, setAnnotationChartYAxis] = useRecoilState(annotationChartYAxisAtom);

  const [chartEventList, setChartEventList] = useState([
    {
      x: 0,//props.chartData.eventList[i].created_at,
      borderColor: '#00E396',
      label: {
        borderColor: '#00E396',
        style: {
          color: '#fff',
          background: '#00E396'
        },
        text: '테스트',
        click: () => onClickEvent('123'),
      }
    }
  ]);
  
  const onClickEvent = (input) => {
    alert(input);
  };
    
  // let tempList1 = [];
  // let tempList2 = [];
  // for(let i = 0; i < 10000; i++){
  //   let randomNumber = Math.floor(Math.random() * 100) + 1;
  //   tempList1.push(`${i+1}`);
  //   tempList2.push(randomNumber);
  // }

  // useEffect(()=>{
  //   setChartOption(tempList1);
  //   //setChartSeries(tempList2);
  // },[]);
  const year = new Date().getFullYear();
  const month = new Date().getMonth() + 1;
  const day1 = new Date().getDate();

  //let baseDateTime = new Date(Date.UTC(year, month, day1, 0, 0, 0));

  const updateGraph = () => {
    if(props.chartData !== null && props.chartData !== undefined && props.chartData.length !== 0){
      //그래프 데이터
      let tempSeriesList = [];
      let tailCount = 0;
     
      for(let i = 0; i < props.chartData.valueList.length; i += props.graphXminutes){
        tailCount++;
        //그래프 맨 앞에 0주기
        if(i === 0){
          let zeroTempDate = new Date(props.chartData.dateTimeList[0]);
          let zeroUtcDate = new Date(Date.UTC(zeroTempDate.getFullYear(), zeroTempDate.getMonth() , zeroTempDate.getDate(), zeroTempDate.getHours(), zeroTempDate.getMinutes(), zeroTempDate.getSeconds()));
          let zeroData = new Date(Date.UTC(zeroTempDate.getFullYear(), zeroTempDate.getMonth(), zeroTempDate.getDate(), 0, 0, 0));
          if(zeroData < zeroUtcDate){
            let tempObject = {
              x: zeroData,                 //props.chartData.dateTimeList[i],
              y: null
            };
            tempSeriesList.push(tempObject);
          }
        }

        let tempDate = new Date(props.chartData.dateTimeList[i]);
        let utcDate = new Date(Date.UTC(tempDate.getFullYear(), tempDate.getMonth() , tempDate.getDate(), tempDate.getHours(), tempDate.getMinutes(), tempDate.getSeconds()));
        let tempObject = null;
        
        if(!isCurrentBloodSugar){
          tempObject = {
            x: utcDate,                 //props.chartData.dateTimeList[i],
            y: Math.round(props.chartData.valueList[i]*100)/100//isCurrentBloodSugar ? Math.round(props.chartData.valueList[i]*100)/100 : Math.floor(props.chartData.valueList[i]) //전류면 소수점 2자리까지 보여주게 하기
          };
        }
        else{
          tempObject = {
            x: utcDate,                 //props.chartData.dateTimeList[i],
            y: Math.floor(props.chartData.valueList[i])
          };
        }
        tempSeriesList.push(tempObject);
        //baseDateTime = new Date(baseDateTime.getTime() + 10* 2000);
      }

      //그래프가 다 안채워졌을 경우, 뒤를 y값 null로 꽉 채워주기          
      let tempDate = new Date(props.chartData.dateTimeList[tailCount - 1]);
     
      let utcDate = new Date(Date.UTC(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDate(), 23, 59, 59));
      
      let tempObject = {
        x: utcDate,                
        y: null
      };
 
      tempSeriesList.push(tempObject);
      setChartSeries(tempSeriesList);
      
      //어노테이션 데이터
      let tempAnnotationList = [];
      
      for(let i = 0; i < props.chartData.eventList.length; i++){
        let typeString = '';
        let contentString = props.chartData.eventList[i].content;
        let colorString = '';
        //1401이면 식사
        if(props.chartData.eventList[i].contents_id == 1401){
          typeString = '식사';
          colorString = '#E9979F';
        }
        //1402이면 활동
        else{
          typeString = '활동';
          colorString = '#B9D7E3';
        }
        
        let tempDate = new Date(props.chartData.eventList[i].created_at);
        let tempObject = {
          x: new Date(Date.UTC(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDate(), tempDate.getHours(), tempDate.getMinutes(), tempDate.getSeconds())).getTime(),
          borderColor: colorString,//'#00E396',
          //offsetY: 20,
          label: {
            borderColor:colorString,// '#00E396',
            style: {
              color: '#fff',
              background: colorString,//'#00E396' 
            },
            text: typeString,//props.chartData.eventList[i].content,
            click: () => onClickEvent(contentString),
          }
        }

        tempAnnotationList.push(tempObject);
      }

      //Max Min 데이터
      let tempAnnotation = new Date(props.chartData.maxValue.created_at);
      let tempUtcDate = new Date(Date.UTC(tempAnnotation.getFullYear(), tempAnnotation.getMonth(), tempAnnotation.getDate(), tempAnnotation.getHours(), tempAnnotation.getMinutes(), tempAnnotation.getSeconds()));
      setMaxData({
        x: tempUtcDate.getTime(),
        y: props.chartData.maxValue.value,
        marker: {
          size: 4,
          fillColor: '#2E7D32',
          strokeColor: '#2E7D32',
          ///radius: 10,
          //cssClass: 'apexcharts-custom-class'
        },
        // label: {
        //   borderColor: '#04B431',
        //   offsetY: 0,
        //   style: {
        //     color: '#fff',
        //     background: '#04B431',
        //   },
    
        //   text: 'max',
        // }
      });
      tempAnnotation = new Date(props.chartData.minValue.created_at);
      tempUtcDate = new Date(Date.UTC(tempAnnotation.getFullYear(), tempAnnotation.getMonth() , tempAnnotation.getDate(), tempAnnotation.getHours(), tempAnnotation.getMinutes(), tempAnnotation.getSeconds()));
      setMinData({
        x: tempUtcDate.getTime(),
        y: props.chartData.minValue.value,
        marker: {
          size: 4,
          fillColor: '#ED6C02',
          strokeColor: '#ED6C02',
          radius: 10,
          //cssClass: 'apexcharts-custom-class'
        },
        // label: {
        //   borderColor: '#FF0040',
        //   offsetY: 0,
        //   style: {
        //     color: '#fff',
        //     background: '#FF0040',
        //   },
    
        //   text: 'min',
        // }
      });

      //Calibration 이벤트 리스트
      for(let i = 0; i < props.chartData.calibrationList.length; i++){
        let tempDate = new Date(props.chartData.calibrationList[i].created_at);
        let tempObject = {
          x: new Date(Date.UTC(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDate(), tempDate.getHours(), tempDate.getMinutes(), tempDate.getSeconds())).getTime(),
          borderColor: '#7C7BBA',//'#00E396',
          label: {
            borderColor: '#7C7BBA',// '#00E396',
            style: {
              color: '#fff',
              background: '#7C7BBA',//'#00E396'
            },
            text: props.chartData.calibrationList[i].value,//props.chartData.eventList[i].content,
            click: () => onClickEvent(props.chartData.calibrationList[i].value),
          }
        }
        tempAnnotationList.push(tempObject);
      }
      setChartEventList(tempAnnotationList);
    }
  }

  useEffect(() => {
    updateGraph();
  }, [isCurrentBloodSugar]);

  useEffect(() => {
    updateGraph();
  },[props.graphXminutes])

  useEffect(()=>{
    updateGraph();
  }, [props.chartData]);

  const options = {
    chart: {
      id: 'basic-line',
      type: 'line',
      // toolbar:{
      //   show: true,
      //   tools:{
      //     zoomin: false
      //   }
      // },
      animations: {
        enabled: false
        //speed: 200
      },
      zoom: {
        type: 'xy',
        enabled: true,
        //autoScaleYaxis: true
      },
    },

    xaxis: {
      type: 'datetime',
      tooltip: {
        enabled: false
      }
      //categories: chartOption//['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    },

    annotations:{
      xaxis: chartEventList,
      yaxis: !isCurrentBloodSugar ? 
      []
      : 
      [
        {
          y: 70,
          y2: 180,
          borderColor: '#000',
          fillColor: '#DCF0FA',
          label: {
            text: '70 - 180'
          }
        }
      ],
      points: [
        maxData,
        minData
      ]
    },

    stroke: {
      curve: 'smooth',
      width:1
    },
    markers:{
      size:0
    },
    tooltip : {
      enabled: true,
      x:{
        format: 'MM월 dd일 HH시 mm분'
      },
      followCursor: true // Add this line to enable tooltip to follow the cursor
    }
  };
  
  options.yaxis = isCurrentBloodSugar?{
    min: 0,
    max: 350
  }: {
    min: annotationChartYAxis.min,
    max: annotationChartYAxis.max
  };

  const series = [
    {
      name: '혈당',
      data: chartSeries,//[80, 40, 25, 50, 49, 21, 70, 51, 60, 80, 45, 35],
    },
  ];

  return (
    <ReactApexChart options={options} series={series} type="line" height={170} />
  );
};

export default AnnotationChart;
