import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useReactToPrint } from "react-to-print";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import LogoImg from "../../assets/uxnicon/157.png";
import SettingsIcon from '@mui/icons-material/Settings';
import Badge from '@mui/material/Badge';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useHistory, withRouter } from "react-router-dom";

import {useRecoilState} from 'recoil';

import {agmsClientRequestGet} from "../../apis/request";

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Drawer from '@mui/material/Drawer';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import secureLocalStorage from "react-secure-storage";
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import {selectedExperimentState, seletedPatientIdState, useRefStateAtom, badgeOnOffAtom, isCurrentBloodSugarAtom} from '../../states/recoilState';

const label = { inputProps: { 'aria-label': 'Switch demo' } };
const pages = ['수검자 리스트', '승인대기 리스트'];
const settings = ['내 정보', '버전 정보' ,'로그아웃'];


function ResponsiveAppBar() {
  const [useRefState, setUseRefState] = useRecoilState(useRefStateAtom);
  const [badgeOnOffState, setBadgeOnOffState] = useRecoilState(badgeOnOffAtom);
  const componentRef = useRef();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [selectedExperimentValue, setSelectedExperiment] = useRecoilState(selectedExperimentState);
  const [centerTitleFlexGrow, setCenterTitleFlexGrow] = useState(1.5);
  const [onOff, setOnOff] = useState(false);
  const [title, setTitle] = useState('');
  const [selectedPatientId, setSeletedPatientId] = useRecoilState(seletedPatientIdState);
  const [badgeInvisible, setBadgeInvisible] = useState(true);

  const [isCurrentBloodSugar, setIsCurrentBloodSugar] = useRecoilState(isCurrentBloodSugarAtom);
  //병원 리스트
  const [hospitalList, setHospitalList] = useState([
    {
      hospital_id: 0,
      hospital_name: ''
    }
  ]);

  const history = useHistory();

  const unmounted = useRef(false);


  //병원 리스트 세트
  const callHospitalApi = async () => {
    let hospitalListResult = await agmsClientRequestGet('/api/hospitals');
    setHospitalList(hospitalListResult);
  }
  const hospitalMenuItemList = hospitalList.map((item, index) => {
    return <MenuItem key={index} value={item.hospital_id}>{item.hospital_name}</MenuItem>;
  });

  useEffect(() => {
    componentRef.current = useRefState;
  }, [useRefState]);

  //문제 있음
  useEffect(()=> {
    //센터 타이틀 Flex Grow 세팅
    //화면 크기 측정 
    // window.addEventListener('resize', () => {
    //   console.log(window.innerWidth);
    // });


    //만약 어드민이면
    if(secureLocalStorage.getItem('role') === 'ADMIN_STAFF'){
      if(history.location.pathname === '/CGMreport'){
        setCenterTitleFlexGrow(1.3);
      }
      else if(history.location.pathname === '/weeklyChartList/list'){
        setCenterTitleFlexGrow(1.3);
      }
      else{
        setCenterTitleFlexGrow(1.5);
      }
    }
    //어드민이 아니면
    else{
      if(history.location.pathname === '/CGMreport'){
        setCenterTitleFlexGrow(0.95);
      }
      else if(history.location.pathname === '/weeklyChartList/list'){
        setCenterTitleFlexGrow(0.9);
      }
      else{
        setCenterTitleFlexGrow(1.1);
      }
    }
    
    //센터 타이틀 이름 세팅
    if(history.location.pathname === '/patient/list'){
      setTitle(isCurrentBloodSugar ? '연속혈당 측정 목록' : '연속혈당전류 측정 목록');
    }
    else if(history.location.pathname === '/CGMreport'){ 
      setTitle(isCurrentBloodSugar ? '연속혈당 측정 보고서' : '연속혈당전류 측정 보고서');
    }
    else if(history.location.pathname === '/weeklyChartList/list'){ 
      setTitle(isCurrentBloodSugar ? '일일 혈당 프로파일' : '일일 혈당전류 프로파일');
    }
    else if(history.location.pathname === '/hospital-profile'){ 
      setTitle('회원 정보');
    }
    else if(history.location.pathname === '/patient/accept/list'){ 
      setTitle('병원 관리자 승인페이지');
    }
    
    //승인대기 인원 체크, 뱃지 반영
    callApprovalPatientListApi().then(data => {
      if(data !== 0){
        setBadgeInvisible(false);
      }else{
        setBadgeInvisible(true);
      }
    });

    //만약 어드민이면 병원 리스트 가져오기
    if(secureLocalStorage.getItem('role') === 'ADMIN'){
      callHospitalApi();
    }

    setIsCurrentBloodSugar(secureLocalStorage.getItem('isCurrentBloodSugar'));
 
  },[]);

  // /**
  //  * 혈당 전류 전환
  //  */
  useEffect(() => {
    //센터 타이틀 이름 세팅
    if(history.location.pathname === '/patient/list'){
      setTitle(isCurrentBloodSugar ? '연속혈당 측정 목록' : '연속혈당전류 측정 목록');
    }
    else if(history.location.pathname === '/CGMreport'){ 
      setTitle(isCurrentBloodSugar ? '연속혈당 측정 보고서' : '연속혈당전류 측정 보고서');
    }
    else if(history.location.pathname === '/weeklyChartList/list'){ 
      setTitle(isCurrentBloodSugar ? '일일 혈당 프로파일' : '일일 혈당전류 프로파일');
    }
    else if(history.location.pathname === '/hospital-profile'){ 
      setTitle('회원 정보');
    }
    else if(history.location.pathname === '/patient/accept/list'){ 
      setTitle('병원 관리자 승인페이지');
    }
  },[isCurrentBloodSugar]);


  //문제 있음
  useEffect(() => {
    //승인대기 인원 체크, 뱃지 반영
    callApprovalPatientListApi().then(data => {
      if(data !== 0){
        setBadgeInvisible(false);
      }else{
        setBadgeInvisible(true);
      }
    });

    //setBadgeInvisible(false);
  },[badgeOnOffState]);

  const callApprovalPatientListApi = async () => {
    try{
      if(secureLocalStorage.getItem('accesstoken') !== null){
        let listResult = await agmsClientRequestGet(`/api/staff/approval/list/${secureLocalStorage.getItem('hospitalId')}`);
        return listResult.length;
      }
      
    }catch(error){

    }
  };

  const handleClickPrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "보고서 프린트" ,
    //onAfterPrint: () => alert('프린트 완료')
  });

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const onMenuClick = (input) =>{
    if(input === '수검자 리스트'){
        history.push('/patient/list');
    }
    else if(input === '승인대기 리스트'){
        history.push('/patient/accept/list');
    }
  };

  const handleSettingMenuClick = async (input) => {
    if(input === '로그아웃'){
        //uuid를 헤더에 담아야함.
        let result = await agmsClientRequestGet(
            `/api/logout`
        );
        secureLocalStorage.removeItem("refreshtoken");
        secureLocalStorage.removeItem("accesstoken");
        secureLocalStorage.removeItem('authUser');
        secureLocalStorage.removeItem('autoLogin');
        history.push('/');
    }else if(input === '내 정보'){
        history.push('/hospital-profile');
    }else{
      history.push('/setting');
      
    }
  }

  const handleIconButtonClick = async (input) =>  {
    if(input === 'Agms 뷰어'){
        history.push('/patient/list');
    }else{
        history.push('/patient/accept/list');
    }
  };
  const toggleDrawer = (newOpen) => () => {
    setOnOff(newOpen);
  };

  const backPage = () => {
    if(history.location.pathname !== '/patient/list'){
      history.goBack();
    }
  }

  const handIsCurrentBloodSugarChange = (event) => {
    setIsCurrentBloodSugar(event.target.checked);
    secureLocalStorage.setItem('isCurrentBloodSugar', event.target.checked);
  }
  

  const callDownloadApi = async() => {
    // let today = new Date();
    // console.log(selectedExperimentValue.name);
    // <a href={`http://118.131.46.69:8080/api/report/download/csv/${selectedPatientId}/${selectedExperimentValue.name}/${selectedExperimentValue.code}?startDate=2024-02-03&endDate=2024-02-04`} >
    //   asdf
    // </a>
    // let downloadResult = await agmsClientRequestGet(`/api/report/download/csv/${selectedPatientId}/${selectedExperimentValue.name}/${selectedExperimentValue.code}?startDate=2024-02-03&endDate=2024-02-04`);
    // console.log(downloadResult);
    
    const url = `https://agmsview.kr:8080/api/report/download/csv/${selectedPatientId}?startDate=2024-02-03&endDate=2024-02-04`;
    const headers = {
      Authorization: `Bearer ${secureLocalStorage.getItem("accesstoken")}`,
    };
    console.log('요요 다운로드');

    console.log(selectedExperimentValue);
    console.log(typeof(selectedExperimentValue));

    axios({
      url,
      method: "GET",
      headers,
      responseType: "blob",
    }).then((response) => {
      const blob = response.data;
      const file = new File([blob], `CGM_${selectedExperimentValue.name}(${selectedExperimentValue.code})_${secureLocalStorage.getItem('startDate')}_${secureLocalStorage.getItem('endDate')}.csv`);
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(file);
      link.download = `CGM_${selectedExperimentValue.name}(${selectedExperimentValue.code})_${secureLocalStorage.getItem('startDate')}_${secureLocalStorage.getItem('endDate')}.csv`;
      link.click();
    });
  }

  const handleClickDownload = () => {
    callDownloadApi();
  }

  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
      <List>
        {['Agms 뷰어', '승인 페이지'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton onClick={() => handleIconButtonClick(text)}>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      {/* <Divider />
      <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List> */}
    </Box>
  );

  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  return (
    <React.Fragment >
        <Drawer open={onOff} onClose={toggleDrawer(false)}>
            {DrawerList}
        </Drawer>
        
        <AppBar position="static" style={{backgroundColor:'#DCD7E2', color:'#5E2589', paddingTop:'5px', paddingBottom:'5px', minWidth:'1625px'}}>
          <Container maxWidth="xl">
            <Toolbar disableGutters>
              {/* 툴바 껍데기 (flex) */}
              <div style={{display:'flex', width:'100%', height:'100%'}}>
                
                {/* 백 버튼 */}
                <div style={{display:'flex', flexDirection:'row',  flex:1}}>
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={() => backPage()}
                    color="inherit"
                    >
                    <ArrowBackIosNewIcon />
                  </IconButton>
 
                  <div style={{display:'flex', justifyContent:'center', alignItems:'center', marginLeft:'20px'}}>
                    {(secureLocalStorage.getItem('role') === 'ADMIN_STAFF' || secureLocalStorage.getItem('role') === 'ADMIN' )&& 
                      <Badge color="error" badgeContent="new" invisible={badgeInvisible}>
                        <Button variant="contained" style={{height:'50%', backgroundColor:'#CDC3D9', color:'#000000', fontWeight:'bold'}} onClick={() =>  history.push('/patient/accept/list')}>
                          승인대기 리스트
                        </Button>
                      </Badge>
                    }
                  </div>

                  
                </div>
                

                {/* 타이틀 */}
                <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', flex:2}}>
                  <img
                      src={LogoImg}
                      style={{ height: "70px", cursor: "pointer", paddingTop:'10px', paddingBottom:'10px', paddingRight:'20px'}}
                      onClick={() => history.push("/patient/list",{
                        selectedExperimentValue: selectedExperimentValue
                      })}
                  />
                  <div style={{fontWeight:'bold', fontSize:'20px'}} onClick={() => history.push("/patient/list",{})}>
                      {title}
                  </div>
                </div>

            

                {/* 설정, 로그아웃 프로필 */}
                <div style={{display:'flex', justifyContent:'end', alignItems:'center' ,flex:'1'}}>
                  {/* 전류 혈당 전환 버튼 */}
                  <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <FormControlLabel control={<Switch color="secondary" checked={isCurrentBloodSugar} onChange={handIsCurrentBloodSugarChange}/>} label={isCurrentBloodSugar ? '혈당': '전류'}/>
                  </div>
                 
                  <div style={{marginRight:'20px'}}>
                    {history.location.pathname === '/CGMreport' && <Button variant="contained" onClick={() => handleClickPrint()} style={{height:'50%', backgroundColor:'#979797', color:'#ffffff', fontWeight:'bold'}}>Print</Button>}
                    {history.location.pathname === '/weeklyChartList/list' && <Button variant="contained" onClick={()=>handleClickDownload()} style={{height:'50%', backgroundColor:'#979797', color:'#ffffff', fontWeight:'bold'}}>Download</Button>}
                  </div>
                  
                  <Tooltip title="설정 열기">
                    <IconButton onClick={handleOpenUserMenu} >
                      <SettingsIcon fontSize="large"/>
                    </IconButton>
                  </Tooltip>
                  <Menu
                    sx={{ mt: '45px' }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                    >
                    {settings.map((setting) => (
                      <MenuItem key={setting} onClick={() => handleSettingMenuClick(setting)}>
                          <Typography textAlign="center" >{setting}</Typography>
                      </MenuItem>
                    ))}
                  </Menu>
                </div>

              </div>
              {/* 툴바 껍데기 (flex) 끝*/}
            </Toolbar>
          </Container>
        </AppBar>

    </React.Fragment>
    
  );
}
export default ResponsiveAppBar;
