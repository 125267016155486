import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import ChangePW from "../pages/Authentication/ChangePW";

// Dashboard
import Dashboard from "../pages/Dashboard/Dashboard";
import Profile from "../pages/Dashboard/Profile";
import Setting from "../pages/Dashboard/Setting";
import MyHospital from "../pages/Dashboard/MyHospital";
import MyDevice from "../pages/Dashboard/MyDevice";
import ConnectApp from "../pages/Dashboard/ConnectApp";
import PatientList from "../pages/Dashboard/PatientList";
import AcceptPatientList from "../pages/Dashboard/AcceptPatientList";
import WeeklyChartList from "../pages/Dashboard/WeeklyChartList";
import CGMreport from "../pages/Dashboard/CGMreport";

// BloodSugar
import BloodSugarPattern from "../pages/BloodSugar/BloodSugarPattern";
import MonthlySummary from "../pages/BloodSugar/MonthlySummary";
import DailyLog from "../pages/BloodSugar/DailyLog";
import SnapShot from "../pages/BloodSugar/SnapShot";
import WeeklySummary from "../pages/BloodSugar/WeeklySummary";
import DailyPattern from "../pages/BloodSugar/DailyPattern";
import DeviceDetails from "../pages/BloodSugar/DeviceDetails";

import BloodSugarRecord from "../pages/DeviceUpload/BloodSugarRecord";
import LoginNewSocial from "../pages/Authentication/LoginNewSocial";

const userRoutes = [
  { path: "/hospital-profile", component: Dashboard },
  { path: "/profile", component: Profile },
  { path: "/setting", component: Setting },
  { path: "/myHospital", component: MyHospital },
  { path: "/myDevice", component: MyDevice },
  { path: "/connectApp", component: ConnectApp },
  { path: "/bloodsugar-record", component: BloodSugarRecord },
  { path: "/patient/list", component: PatientList },
  { path: "/patient/accept/list", component: AcceptPatientList },
  { path: "/weeklyChartList/list", component: WeeklyChartList },
  { path: "/CGMreport", component: CGMreport },
  // { path: "/*", component: NotFound},
  // this route should be at the end of all other routes
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/patient/list" />,
  },
];

const bloodSugarRoutes = [
  { path: "/haldang/bloodsugar-pattern", component: BloodSugarPattern },
  { path: "/haldang/monthly-summary", component: MonthlySummary },
  { path: "/haldang/daily-log", component: DailyLog },
  { path: "/haldang/snapshot", component: SnapShot },
  { path: "/haldang/weekly-summary", component: WeeklySummary },
  { path: "/haldang/daily-pattern", component: DailyPattern },
  { path: "/haldang/device-details", component: DeviceDetails },
];

const authRoutes = [
  { path: "/login", component: Login },
  { path: "/newSocialSignin", component: LoginNewSocial },
  { path: "/changepw", component: ChangePW },
  { path: "/changepw/:email/:code", component: ChangePW },
];

export { userRoutes, authRoutes, bloodSugarRoutes };
