import React, { useEffect, useRef, useState } from "react";
import { withRouter, useHistory,useLocation } from "react-router-dom";

import {atom, useRecoilState} from 'recoil';

import {selectedExperimentState,seletedPatientIdState,weeklyLastDateState,isCurrentBloodSugarAtom, annotationChartYAxisAtom} from '../../states/recoilState';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import CircularProgress from '@mui/material/CircularProgress';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import TextField from '@mui/material/TextField';

// import { Label } from "reactstrap";
// import '../../App.css'
//jQuery librarie
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-select/js/dataTables.select"
import "datatables.net-dt/css/jquery.dataTables.min.css"

import AnnotationChart from './Charts/AnnotationChart';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import { CardContent, Typography } from "@mui/material";
import { agmsClientRequestGet } from "../../apis/request";
import secureLocalStorage from "react-secure-storage";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  border: '1.5px solid #D3D3D3'
}));

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function valuetext(value) {
  return `${value}°C`;
}


const WeeklyChartList = () => {
  const history = useHistory();
  const location = useLocation();
  const [selectedExperimentValue, setSelectedExperiment] = useRecoilState(selectedExperimentState);
  const [weeklyLastDate, setWeeklyLastDate] = useRecoilState(weeklyLastDateState);
  const [cardList, setCardList] = useState([]);
  const [selectedPatientId, setSeletedPatientId] = useRecoilState(seletedPatientIdState);
  const [dateList, setDateList] = useState([]);

  const [graphLoading, setGraphLoading] = useState(true);
  const [isCurrentBloodSugar, setIsCurrentBloodSugar] = useRecoilState(isCurrentBloodSugarAtom);

  const [dateRange, setDateRange] = useState({
    startYear: '',
    startMonth: '',
    startDate: '',
    endYear: '',
    endMonth: '',
    endDate: ''
  });
  const [patientValue, setPatientValue] = useState({
    name: '',
    code: ''
  }); 
  const [apiResult, setApiResult] = useState(
    {
      activePercent:[],
      maxGlucose:[],
      minGlucose:[],
      eventCount:[],

      dayGraphData:[],
      sbgmcount:[],
      exceedLowerPercent:[],
      exceedUpperPercent:[],
      avgGlucose:[]
    }
  );
 
  const [maxList, setMaxList] = useState([
    {
      max_value: null,
      created_at: null
    }
  ]);
  const [minList, setMinList] = useState([
    {
      min_value: null,
      created_at: null
    }
  ]);

  const days = ['일','월','화','수','목','금','토'];

  const [xMinutes, setXminutes] = React.useState(1);

  const handleChange = (event) => {
    setXminutes(event.target.value);
  };

  const onCGMreport = () =>{
    history.push('/CGMreport', {
      middleAreaSeriesData: location.state.middleAreaSeriesData,
      mainUpperAreaSeriesData: location.state.mainUpperAreaSeriesData,
      mainLowerAreaSeriesData: location.state.mainLowerAreaSeriesData,
      lineSeriesData: location.state.lineSeriesData,
      upperLimit: location.state.upperLimit,
      lowerLimit: location.state.lowerLimit
    });
  }

  const callApi = async(input) =>{
    setGraphLoading(true);
    try{
      //API 호출
      let result = null;
      if(isCurrentBloodSugar){
        console.log('isCurrentBloodSugar true'+ isCurrentBloodSugar);
        result = await agmsClientRequestGet(
          `/api/report/daily/${input}?strValueType=1308`
        );
  
      }else{
        console.log('isCurrentBloodSugar false'+ isCurrentBloodSugar);
        result = await agmsClientRequestGet(
          `/api/report/daily/${input}`
        );  
      }
      
      //다운로드 시에 사용할 마지막 날짜
      let tempDate = new Date(result.dayGraphData[result.eventCount.length-1].dateTimeList[0]);
      setWeeklyLastDate();

      //좌측에 표시할 날짜 리스트를 만들어준다.
      let tempDateStringList = [];
      for(let i = 0; i< result.eventCount.length; i++){
        if(result.dayGraphData[i].maxValue === null || result.dayGraphData[i].minValue === null){
          continue;
        }
        let tempDate = new Date(result.dayGraphData[i].dateTimeList[0]);
        let tempString = (tempDate.getMonth() + 1) + '/' + tempDate.getDate() + '(' + days[tempDate.getDay()]+')'; 
        tempDateStringList.push(tempString);
      }
      setDateList(tempDateStringList);

      //좌측 상단에 나타날 기간 값을 지정해준다.
      let startDate = new Date(result.dayGraphData[0].dateTimeList[0]);
      let endDate = new Date(result.dayGraphData[result.eventCount.length-1].dateTimeList[0]);
 
      setDateRange({
        startYear: startDate.getFullYear(),
        startMonth: (startDate.getMonth() + 1) < 10 ? '0' + (startDate.getMonth() + 1):  startDate.getMonth() + 1 ,
        startDate: startDate.getDate(),
        endYear: endDate.getFullYear(),
        endMonth: (endDate.getMonth() + 1) < 10 ? '0' + (endDate.getMonth() + 1):  endDate.getMonth() + 1 ,
        endDate: endDate.getDate()
      });
      
      //날짜 갯수만큼 정보를 담아 카드 리스트를 만들어준다.
      let tempList = [];
      if(result !== null){
        for(let i = 0; i < result.maxGlucose.length; i++){
          if(result.dayGraphData[i].maxValue === null || result.dayGraphData[i].minValue === null){
            continue;
          }
          var tempObject = {
            dayGraphData: result.dayGraphData[i],
            avgGlucose: result.avgGlucose[i],
            exceedUpperPercent: result.exceedUpperPercent[i],
            exceedLowerPercent: result.exceedLowerPercent[i]
          }

          tempList.push(tempObject);
        }
        setCardList(tempList);
      }
      

      //최고혈당, 최저혈당 리스트 set
      let maxTempList = [];
      let minTempList = [];
      for(let i = 0; i < result.dayGraphData.length; i++){
        if(result.dayGraphData[i].maxValue === null || result.dayGraphData[i].minValue === null){
          continue;
        }
      
        let maxDate = new Date(result.dayGraphData[i].maxValue.created_at );
        let minDate = new Date(result.dayGraphData[i].minValue.created_at);
        
        maxTempList.push(
          {
            max_value: result.dayGraphData[i].maxValue.value,
            created_at: (maxDate.getHours() <= 9 ? ('0' + maxDate.getHours()) : maxDate.getHours()) + ':' + (maxDate.getMinutes() <= 9 ? ('0'+ maxDate.getMinutes()) : maxDate.getMinutes())
          }
        );
        minTempList.push(
          {
            min_value: result.dayGraphData[i].minValue.value,
            created_at: (minDate.getHours() <= 9 ? ('0' + minDate.getHours()) : minDate.getHours()) + ':' + (minDate.getMinutes() <= 9 ? ('0'+ minDate.getMinutes()) : minDate.getMinutes())
          }
        );
      }
      setMaxList(maxTempList);
      setMinList(minTempList);
      setApiResult(result);
    }
    catch(error){
      setGraphLoading(false);
    }
    setGraphLoading(false);
  }

  useEffect(() => {
    callApi(secureLocalStorage.getItem("selectedPatientId"));
  },[isCurrentBloodSugar]);

  useEffect(() => {
    callApi(secureLocalStorage.getItem("selectedPatientId"));
  },[selectedPatientId]);

  useEffect(() => {
    secureLocalStorage.setItem('startDate', dateRange.startYear + '-' + dateRange.startMonth + '-' + dateRange.startDate);
    secureLocalStorage.setItem('endDate', dateRange.endYear + '-' + dateRange.endMonth + '-' + dateRange.endDate);
  },[dateRange])

  useEffect(()=>{
    let tempUserData = JSON.parse(secureLocalStorage.getItem('selectedExperimentValue'));
    setPatientValue({
      name: tempUserData.name,
      code: tempUserData.code
    });
  },[]);


  const [annotationChartYAxis, setAnnotationChartYAxis] = useRecoilState(annotationChartYAxisAtom);

  const textFieldMaxRef = useRef(200);
  const textFieldMinRef = useRef(-200);

  const [open, setOpen] = React.useState(false);
 

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [yAxisMaxValue, setYAxisMaxValue] = React.useState(200);
  const onChangeYAxisMaxValue = (event) =>{
    setYAxisMaxValue(event.target.value);
  }
  const [yAxisMinValue, setYAxisMinValue] = React.useState(-200);
  const onChangeYAxisMinValue = (event) =>{
    setYAxisMinValue(event.target.value);
  }

  const onSaveYAxisValue = () => { 
    let tempMax = textFieldMaxRef.current.value;
    let tempMin = textFieldMinRef.current.value;
    
    if(typeof(tempMax) == 'string'){
      tempMax = parseInt(tempMax);
    }
    if(typeof(tempMin) == 'string'){
      tempMin = parseInt(tempMin);
    }
    setAnnotationChartYAxis({
      min: tempMin,
      max: tempMax
    });
    callApi(secureLocalStorage.getItem("selectedPatientId"));
    setOpen(false);
  }

  return (
    <div
      className="row w-100 h-100 "
      style={{ padding: "0px 70px", marginTop: "30px"}}
    >
      <div className="panel-body" style={{maxWidth:'1500px', minWidth:'1500px', marginLeft:'auto', marginRight:'auto'}}>
        <div style={{width: '100%', marginTop: "10px"}}></div>
        
        <div className="row ">
          <div className="col">
            <div style={{display:'flex',  alignItems:'center', marginBottom:'10px'}}>
              <div style={{flex: 0.8, fontSize:'23px'}}>
                기간 : {dateRange.startYear}-{dateRange.startMonth}-{dateRange.startDate} ~ {dateRange.endYear}-{dateRange.endMonth}-{dateRange.endDate}
              </div>

              <div style={{flex:0.2}}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">그래프 간격</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={xMinutes}
                    label="그래프 간격"
                    onChange={handleChange}
                  >
                    <MenuItem value={1}>1분</MenuItem>
                    <MenuItem value={5}>5분</MenuItem>
                    <MenuItem value={15}>15분</MenuItem>
                    <MenuItem value={30}>30분</MenuItem>
                    <MenuItem value={60}>1시간</MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div style={{flex:0.2, paddingLeft:'20px'}}>
                <Button 
                  variant="contained" 
                  size="medium" 
                  style={{ backgroundColor : '#DCD7E2', color:'#000000', fontSize:'16px'}}
                  onClick={handleOpen}
                >
                  Y축 간격
                </Button>
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={open}
                  onClose={handleClose}
                  closeAfterTransition
                  slots={{ backdrop: Backdrop }}
                  slotProps={{
                    backdrop: {
                      timeout: 500,
                    },
                  }}
                >
                  <Fade in={open}>
                    <Box sx={style}>
                    
                      <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', marginRight:'5px', fontSize:'15px'}}>
                          Y축 최대 : 
                        </div>
                        <TextField inputRef={textFieldMaxRef} id="outlined-basic" label="최댓값" variant="outlined" />
                      </div>
                      <div style={{height:'20px'}}></div>

                      <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', marginRight:'5px', fontSize:'15px'}}>
                          Y축 최소 : 
                        </div>
                        <TextField inputRef={textFieldMinRef} id="outlined-basic" label="최솟값" variant="outlined" />
                      </div>
                      
                      <div>
                      <Button onClick={onSaveYAxisValue} style={{fontSize:'15px'}}>저장</Button>
                      </div>
                    </Box>
                  </Fade>
                </Modal>
              </div>


              <div style={{flex: 0.7, display:'flex', flexDirection:'row',justifyContent:'center', alignItems:'center'}}>
                <div style={{display:'flex', flexDirection:'row'}}>
                  <div style={{backgroundColor:'#7C7BBA', width:'30px', borderRadius:'5px'}} />
                  <div style={{marginLeft:'5px'}}>
                    Calibration
                  </div>
                </div> 
                <div style={{display:'flex', flexDirection:'row'}}>
                  <div style={{backgroundColor:'#E9979F', width:'30px',borderRadius:'5px', marginLeft:'15px'}} />
                  <div style={{marginLeft:'5px'}}>
                    식사
                  </div>
                </div> 
                <div style={{display:'flex', flexDirection:'row'}}>
                  <div style={{backgroundColor:'#B9D7E3', width:'30px',borderRadius:'5px',marginLeft:'15px'}} />
                  <div style={{marginLeft:'5px'}}>
                    활동
                  </div>
                </div> 
              </div>

              {/* <div style={{flex: 0.8, display:'flex', justifyContent:'center' ,alignItems:'center', fontSize:'23px'}}>
                성명 : {patientValue.name} ({patientValue.code})
              </div> */}

              <div style={{flex: 1, display:'flex', justifyContent:'end', alignItems:'center'}}>
                <span style={{fontSize:'23px', paddingRight:'15px'}}>성명 : {patientValue.name} ({patientValue.code})</span>  
                <Button variant="contained" size="medium" style={{ backgroundColor : '#DCD7E2', color:'#000000', fontSize:'16px'}} onClick={onCGMreport}> 
                  <b>보고서 보기</b>
                </Button>
              </div>
            </div>

            {/* <div style={{width:'200px', marginBottom:'10px', marginTop:'10px'}}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">그래프 간격</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={xMinutes}
                  label="그래프 간격"
                  onChange={handleChange}
                >
                  <MenuItem value={1}>1분</MenuItem>
                  <MenuItem value={5}>5분</MenuItem>
                  <MenuItem value={15}>15분</MenuItem>
                  <MenuItem value={30}>30분</MenuItem>
                  <MenuItem value={60}>1시간</MenuItem>
                </Select>
              </FormControl>
            </div> */}
            <Grid container spacing={1}>
              {cardList.map((item, index) => (
                <Grid xs={12} key={index}>
                  <Item>
                     <div className="row align-items-center justify-content-between">
                        <div className="col-1">
                          <div style={{fontSize:'30px'}}><b>{dateList[index]}</b></div>
                          <div style={{fontSize:'15px'}}>혈당({isCurrentBloodSugar ? 'mg/dL' : 'nA'})</div>
                          <div></div>
                        </div>
                        <div className="col-6" >
                          {graphLoading == true ? <CircularProgress /> : <AnnotationChart chartData={item.dayGraphData} graphXminutes={xMinutes}/>}
                        </div>
                        <div className="col-5" >
                          <Grid container spacing={1}>
                            <Grid xs={2.4}>
                              <Paper style={{border:'1px solid #000000', padding:'1em', marginLeft:'5px'}}>
                                <Badge color='success' badgeContent=" " variant="dot">
                                  <div className="row" style={{fontSize:'16px', display:'flex', justifyContent:'center', alignItems:'center', height:'30px'}}>
                                    최고{isCurrentBloodSugar ? '혈당' : '전류'}
                                  </div>
                                </Badge>
                                <div>{isCurrentBloodSugar ? 'mg/dL' : 'nA'}</div>
                                <div className="row align-items-center justify-content-between" style={{fontSize: '30px', height:'50px', color:'#2E7D32'}}>
                                  <b>{maxList[index] !== undefined  && (isCurrentBloodSugar ? Math.round(maxList[index].max_value) : Math.floor(maxList[index].max_value *100)/100) }</b> 
                                </div>
                                <div>
                                  {maxList[index]!== undefined  && maxList[index].created_at }
                                </div>
                              </Paper>
                            </Grid>
                            <Grid xs={2.4}>
                              <Paper style={{border:'1px solid #000000', padding:'1em'}}>
                                <Badge color="warning" badgeContent=" " variant="dot"> 
                                  <div className="row" style={{fontSize:'16px', display:'flex', justifyContent:'center', alignItems:'center', height:'30px'}}>
                                    최저{isCurrentBloodSugar ? '혈당' : '전류'}
                                  </div>
                                </Badge>
                                <div>{isCurrentBloodSugar ? 'mg/dL' : 'nA'}</div>
                                <div className="row align-items-center justify-content-between" style={{fontSize: '30px', height:'50px', color:'#ED6C02'}}>
                                  <b>{minList[index] !== undefined && (isCurrentBloodSugar ? Math.round(minList[index].min_value) : Math.floor(minList[index].min_value *100)/100) }</b>
                                </div>
                                <div>
                                  {minList[index]!== undefined  && minList[index].created_at }
                                </div>
                              </Paper>
                            </Grid>
                            <Grid xs={2.4}>
                              <Paper style={{border:'1px solid #000000',padding:'1em'}}>
                                <div className="row" style={{fontSize:'16px', display:'flex', justifyContent:'center', alignItems:'center', height:'30px'}}>
                                  평균{isCurrentBloodSugar ? '혈당' : '전류'}
                                </div>
                                <div>{isCurrentBloodSugar ? 'mg/dL' : 'nA'}</div>
                                <div className="row align-items-center justify-content-between" style={{fontSize: '30px', height:'70px', }}>
                                  <b>{isCurrentBloodSugar ? Math.round(item.avgGlucose) : Math.floor(item.avgGlucose*100)/100}</b>
                                </div>
                              </Paper>
                            </Grid>
                            <Grid xs={2.4}>
                              <Paper style={{border:'1px solid #000000',padding:'1em'}}>
                                <div className="row" style={{fontSize:'16px', display:'flex', justifyContent:'center', alignItems:'center', height:'30px'}}>
                                  상한치 초과
                                </div>
                                <div>
                                  %
                                </div>
                                <div className="row align-items-center justify-content-between" style={{fontSize: '30px', height:'70px', }}><b>{item.exceedUpperPercent}</b></div>
                              </Paper>
                            </Grid>
                            <Grid xs={2.4}>
                              <Paper style={{border:'1px solid #000000',padding:'1em'}}>
                                <div className="row" style={{fontSize:'16px', display:'flex', justifyContent:'center', alignItems:'center', height:'30px'}}>
                                  하한치 미달
                                </div>
                                <div>
                                  %
                                </div>
                                <div className="row align-items-center justify-content-between" style={{fontSize: '30px', height:'70px', }}><b>{item.exceedLowerPercent}</b></div>
                              </Paper>
                            </Grid>
                          </Grid>
                        </div>
                     </div>
                  </Item>
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(WeeklyChartList);
