import React, { useEffect, useRef, useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, height } from "@fortawesome/free-solid-svg-icons/faMagnifyingGlass";
import "./main.scss";
import { requestGet, agmsClientRequestGet, agmsClientRequestPost } from "../../apis/request";
import Loading from "../../assets/uxnicon/loadingIcon.png";
import moment from "moment";

import { userID, selectedExperimentState, seletedPatientIdState, isCurrentBloodSugarAtom } from "../../states/recoilState";
import { useSetRecoilState } from "recoil";
import {atom, useRecoilState} from 'recoil';
import { Redirect } from "react-router-dom";

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { FixedSizeList } from 'react-window';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import PatientTable from './PatientListComponents/PatientTable'
import RangeAreaChart from './Charts/RangeAreaChart';
import SearchTextfield from "./Inputs/SearchTextfield";

import Button from '@mui/material/Button';
import agmsLogo from './imgs/agms 2.svg';

// import { Label } from "reactstrap";

// import '../../App.css'
//jQuery librarie
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-select/js/dataTables.select"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'; 


import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import secureLocalStorage from "react-secure-storage";

import MenuItem from '@mui/material/MenuItem';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  border: '1.5px solid #5E2589'
}));


const PatientList = () => {
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const setuserID = useSetRecoilState(userID);
  const history = useHistory();
  const location = useLocation();
  const [tableData, setTableData] = useState([]);

  const [searchInputValue, setSearchInputValue] = useState('');
  const [selectedExperimentValue, setSelectedExperiment] = useRecoilState(selectedExperimentState);

  //요약 그래프용
  const [lineSeriesData, setLineSeriesData] = useState([]);
  const [middleAreaSeriesData, setMiddleAreaSeriesData] = useState([]);
  const [mainUpperAreaSeriesData, setUpperMainAreaSeriesData] = useState([]);
  const [mainLowerAreaSeriesData, setLowerMainAreaSeriesData] = useState([]);

  const [upperLimit, setUpperLimit]= useState([]);
  const [lowerLimit, setLowerLimit]= useState([]);

  const [selectedPatientId, setSeletedPatientId] = useRecoilState(seletedPatientIdState);
  const [isCurrentBloodSugar, setIsCurrentBloodSugar] = useRecoilState(isCurrentBloodSugarAtom);

  //평균값
  const [averageData, setAverageData] = useState([]);
  //최고값
  const [maxData, setMaxData] = useState([]);
  //최저값
  const [minData, setMinData] = useState([]);
  //측정일수
  const [measurementPeriod, setMeasurementPeriod] = useState([]);
  //CGM활성화율
  const [activePercent, setActivePercent] = useState([]);
  //기기타입
  const [deviceTypeInfo, setDeviceTypeInfo] = useState([]);

  //로그인 창 다음인지
  const [isAfterLogin, setIsAfterLogin]= useState(false);

  const handleToWeeklyChart = () => {
    history.push('/weeklyChartList/list', {
      middleAreaSeriesData: middleAreaSeriesData,
      mainUpperAreaSeriesData: mainUpperAreaSeriesData,
      mainLowerAreaSeriesData: mainLowerAreaSeriesData,
      lineSeriesData: lineSeriesData,
      upperLimit: upperLimit,
      lowerLimit: lowerLimit
    });
    //history.push('/weeklyChartList/list');
  }

   //병원 리스트
  const [hospitalList, setHospitalList] = useState([
    {
      hospital_id: 0,
      hospital_name: ''
    }
  ]);

  //병원 리스트 세트
  const callHospitalApi = async () => {
    let hospitalListResult = await agmsClientRequestGet('/api/hospitals');
    setHospitalList(hospitalListResult);
  }
  const hospitalMenuItemList = hospitalList.map((item, index) => {
    return <MenuItem key={index} value={item.hospital_id}>{item.hospital_name}</MenuItem>;
  });

  //테이블 로우 값 가져오기
  const callApi = async () => {
    setTableLoading(true);
    const userId = secureLocalStorage.getItem("authUser");
    try {
      let res = null;

      //만약 Admin Staff이면 staff id가 아니라 hospital id를 기점으로 patient를 가져온다.
      if(secureLocalStorage.getItem('role') === 'ADMIN_STAFF'){
        res = await agmsClientRequestGet(
          `/api/staff/info/patient/device/hospital/${secureLocalStorage.getItem('hospitalId')}/0.0`
        );
      }
      else if(secureLocalStorage.getItem('role') === 'ADMIN'){
        res = await agmsClientRequestGet(
          `/api/staff/info/patient/device/hospital/${hospital}/0.0`
        );
      }
      //아니면 staff id에 종속된 patient id만 가져온다.
      else {
        res = await agmsClientRequestGet(
          `/api/staff/info/patient/device/${userId}/0.0`
        );
      }
      setTableLoading(false);

      let patientTableList = [];
      
      res.forEach(element => {
        if(element.last_date !== null){
          let temp = {
            name: element.patient_name,
            code: element.identification,
            createdDate : element.last_date,
            patient_id : element.patient_id
          }
          patientTableList.push(temp);
        }
      });
      
      setTableData(patientTableList);//data에 저장
    } catch (error) {
      callApi();
    } 

  };
  
  //요약 그래프
  const callSummaryApi = async (input) =>{
    setLoading(true);
    //API Call
    console.log('summary 인풋 : '+ input);
    let result = undefined;
    //로그인 되어 있을 때만 실행
    if(secureLocalStorage.getItem('accesstoken') !== null){
      if(!isCurrentBloodSugar){
        result = await agmsClientRequestGet(`/api/report/summary/${input}`);
      }else{
        result = await agmsClientRequestGet(`/api/report/summary/${input}?strValueType=1308`);

      }
    }
         
    setAverageData(result.avgGlucose);
    setMaxData(result.maxGlucose);
    setMinData(result.minGlucose);
    setMeasurementPeriod(result.measurementPeriod);
    setActivePercent(result.activePercent);
    setDeviceTypeInfo(result.deviceType);
    
    //유효범위
    setUpperLimit(result.upperLimit);
    setLowerLimit(result.lowerLimit);

    if(result !== undefined){      
      let tempLineList = [];
      let tempMiddelAreaList = [];
      let tempUpperMainAreaList = [];
      let tempLowerMainAreaList = [];
      
      //Area그래프에 props로 넘겨줄 데이터를 가공합니다.
      for(let i = 0; i < result.profileGraphData["50%"].length; i += 4) {
        let beforeUtcTempDate = new Date("2022-01-02T00:00:00");
        let tempDate = new Date(Date.UTC(beforeUtcTempDate.getFullYear(), beforeUtcTempDate.getMonth(),beforeUtcTempDate.getDate(),beforeUtcTempDate.getHours(),beforeUtcTempDate.getMinutes(),beforeUtcTempDate.getSeconds()));
        tempDate.setMinutes(tempDate.getMinutes() + 15 * i);

        let tempObject = {
          x: tempDate,
          y: Math.floor(result.profileGraphData["50%"][i])
        }
        tempLineList.push(tempObject);
        
        let tempObject1 = {
          x: tempDate,
          y: [ Math.floor(result.profileGraphData["25%"][i]),  Math.floor(result.profileGraphData["75%"][i]) ] 
        }
        tempMiddelAreaList.push(tempObject1);

        let tempObject2 = {
          x: tempDate,
          y: [ Math.floor(result.profileGraphData["75%"][i]),  Math.floor(result.profileGraphData["95%"][i]) ] 
        }
        tempUpperMainAreaList.push(tempObject2);

        let tempObject3 = {
          x: tempDate,
          y: [ Math.floor(result.profileGraphData["5%"][i]),  Math.floor(result.profileGraphData["25%"][i]) ] 
        }
        tempLowerMainAreaList.push(tempObject3);
      }

      setLineSeriesData(tempLineList);
      setMiddleAreaSeriesData(tempMiddelAreaList);
      setUpperMainAreaSeriesData(tempUpperMainAreaList);
      setLowerMainAreaSeriesData(tempLowerMainAreaList);
    }else{
    }
    setLoading(false);
    return result;
  }

  const callLogoutApi = async () => {
    let result = await agmsClientRequestGet(
      `/api/logout`
    );
    secureLocalStorage.removeItem("refreshtoken");
    secureLocalStorage.removeItem("accesstoken");
    secureLocalStorage.removeItem('authUser');
    secureLocalStorage.removeItem('autoLogin');
    history.push('/login');
  
  }

  useEffect(() => {
    if(location.state === undefined){
     
      //자동 로그인이 없으면 로그아웃 후 로그인 화면으로
      if(secureLocalStorage.getItem('autoLogin') === null){
        callLogoutApi();
        secureLocalStorage.removeItem("refreshtoken");
        secureLocalStorage.removeItem("accesstoken");
        secureLocalStorage.removeItem('authUser');
        secureLocalStorage.removeItem('autoLogin');
        history.push('/login');
      }
      else{
        callApi();
      }
    }
    else if(location.state.isAfterLogin === true){
      console.log('location.state2');
      console.log(location.state);
      callApi();
      setSelectedExperiment(null);
      setIsAfterLogin(true);
      setSeletedPatientId(null);
    }
    else{
      callApi();
    }

    //만약 어드민이면 병원 리스트 가져오기
    if(secureLocalStorage.getItem('role') === 'ADMIN'){
      callHospitalApi();
    }

    //병원 선택 
    setHospital(secureLocalStorage.getItem('hospitalId'));

  }, []);

  useEffect(() => {
    if(selectedPatientId !== null){
      callSummaryApi(selectedPatientId);
    }
  }, [selectedPatientId]);

  useEffect(() => {
    if(selectedPatientId !== null){
      callSummaryApi(selectedPatientId);
    }
  }, [isCurrentBloodSugar]);

  const fontSize = {
    fontSize: '36px', // Set your desired font size here
  };

  const [hospital, setHospital] = useState(secureLocalStorage.getItem('hospitalId'));

  //병원 선택 시
  useEffect(() => {
    if(location.state === undefined){
      //자동 로그인이 없으면 로그아웃 후 로그인 화면으로
      if(secureLocalStorage.getItem('autoLogin') === null){
        callLogoutApi();
        secureLocalStorage.removeItem("refreshtoken");
        secureLocalStorage.removeItem("accesstoken");
        secureLocalStorage.removeItem('authUser');
        secureLocalStorage.removeItem('autoLogin');
        history.push('/login');
      }
      else{
        callApi();
      }
    }
    else if(location.state.isAfterLogin === true){
      callApi();
    }
    else{
      callApi();
    }
    secureLocalStorage.setItem('hospitalId', hospital);
  }, [hospital]);

  const handleChange = (event) => {
    setHospital(event.target.value);
  };
  
  return (
    <div
      className="row w-100 h-100 d-flex align-items-center"
      style={{ padding: "0px 70px", }}
    >
      
      <div className="panel-body" style={{maxWidth:'1500px', minWidth:'1500px',marginLeft:'auto', marginRight:'auto'}}>
        
        <div style={{
          width: '100%', 
          height: secureLocalStorage.getItem('role') === 'ADMIN' ? '60px':'50px', 
          display:'flex', 
          alignItems:'center', 
          justifyContent:'end', 
          marginTop: secureLocalStorage.getItem('role') === 'ADMIN'? '10px': '0px'}}>
          {secureLocalStorage.getItem('role') === 'ADMIN' &&
            <FormControl sx={{ m: 1, width: 250, backgroundColor:'white' }} size="small" style={{margin:'0'}}>
              <InputLabel 
                id="demo-select-small-label"
                color="secondary">
                병원
              </InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={hospital}
                label="병원선택"
                color="secondary"
                onChange={handleChange}
              >
                {hospitalMenuItemList}
              </Select>
            </FormControl>
          }
          
        </div>

  
        <div className="row">
        
          <div className="col">  
            <SearchTextfield/>

            <div style={{height:'15px'}}></div>

            {
              tableLoading === true ?
              <div style={{
                display:'flex', 
                justifyContent:'center',
                alignItems:'center',
                backgroundColor:'white',
                maxHeight:'800px', 
                height:'75vh',
                border:'1px solid black',
                borderRadius:'3px'}}> 
                <CircularProgress color="secondary" />
              </div>
              :
              <PatientTable 
                tableData = {tableData} 
                isAfterLogin = {isAfterLogin}/>
            }
            {/* <PatientTable 
                tableData = {tableData} 
                isAfterLogin = {isAfterLogin}/> */}
            
          </div>
          
          <div style={{width:'10px', height:'10px'}}></div>

          <div className="col">
            {(selectedExperimentValue === 'empty' ||  selectedExperimentValue === null || selectedExperimentValue === '' 
              || lineSeriesData.length === 0 || middleAreaSeriesData.length === 0 || mainUpperAreaSeriesData.length === 0 || mainLowerAreaSeriesData.length === 0) && loading === false  ?            
            (
              <Card sx={{ minWidth: 275, height: '100%', display:'flex', justifyContent:'center', alignItems:'center', border: '1px solid #8A8A8A'}}>
                <CardContent>
                  <div style={{display:'flex', flexDirection:'column', justifyContent:'center',  alignItems:'center'}}>
                    <img src={agmsLogo} style={{width:'80%'}}></img>
                    <div style={{textAlign:'center', marginTop:'10px', fontWeight:'bold'}}>
                      왼쪽 목록에서 수검자를 선택해 주세요.
                    </div>
                  </div>
                </CardContent>
              </Card>  
            ) 
            : 
            (
              <Card sx={{ minWidth: 275, height: '100%', border: '1px solid #8A8A8A'}}>
                {loading !== true ? 
                  <CardContent style={{height:'100%'}}>
                    <Box style={{display:'flex', flexDirection:'column',height:'100%'}}>
                      <Box sx={{ flex: 1}}>
                        <Grid container spacing={2}>
                          <Grid xs={4}>
                            <Item >
                              <div className="row" style={{fontSize:'16px'}}>평균 값({isCurrentBloodSugar? 'mg/dL':'nA'})</div>
                              <div className="row" style={fontSize}><b>{Math.round(averageData)}</b></div>
                            </Item>
                          </Grid>
                          <Grid xs={4}>
                            <Item >
                              <div className="row" style={{fontSize:'16px'}}>최고 값({isCurrentBloodSugar? 'mg/dL':'nA'})</div>
                              <div className="row" style={fontSize}><b>{Math.round(maxData)}</b></div>
                            </Item>
                          </Grid>
                          <Grid xs={4}>
                            <Item >
                              <div className="row" style={{fontSize:'16px'}}>최저 값({isCurrentBloodSugar? 'mg/dL':'nA'})</div>
                              <div className="row" style={fontSize}><b>{Math.round(minData)}</b></div>
                            </Item>
                          </Grid>

                          <Grid xs={4}>
                            <Item >
                              <div className="row" style={{fontSize:'16px'}}>측정일수</div>
                              <div className="row" style={fontSize}><b>{measurementPeriod}일</b></div>
                            </Item>
                          </Grid>
                          <Grid xs={4}>
                            <Item >
                              <div className="row" style={{fontSize:'16px'}}>CGM 활성화율(%)</div>
                              <div className="row" style={fontSize}><b>{activePercent}</b></div>
                            </Item>
                          </Grid>
                          <Grid xs={4}>
                            <Item >
                              <div className="row" style={{fontSize:'16px'}}>기기타입</div>
                              <div className="row" style={fontSize}><b>{deviceTypeInfo}</b></div>
                            </Item>
                          </Grid>
                        </Grid>
                      </Box>
                      
                      <Box style={{flex: 4, marginTop:'20px', marginBottom:'20px', textAlign:'center'}}>
                        <RangeAreaChart 
                          middleAreaSeriesData={middleAreaSeriesData} 
                          mainUpperAreaSeriesData={mainUpperAreaSeriesData} 
                          mainLowerAreaSeriesData={mainLowerAreaSeriesData} 
                          lineSeriesData={lineSeriesData} height='100%' 
                          upperLimit = {upperLimit}
                          lowerLimit = {lowerLimit} />
                      </Box>
                      
                      <Box style={{flex: 0.2, display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <Button fullWidth variant="contained" size="medium" style={{backgroundColor : '#DBD7E1', color:'#000000', fontSize:'16px'}} onClick={handleToWeeklyChart}> 
                            <b>일일 데이터 보기</b>
                        </Button>
                      </Box>
                    </Box>
            
                  </CardContent>
                : 
                  <CardContent style={{height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <div >
                      <CircularProgress />
                    </div>
                  </CardContent>
                }
                
              </Card>  
            )}
            </div>
          </div>
      </div>
    </div>
  );
};

export default withRouter(PatientList);
